/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Extra+Condensed&display=swap');

/* App.css */
.background-dark {
  background-color: #000000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  font-size: calc(10px + 2vmin);
  color: #181818;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* In your App.css file */
.flex-container {
  display: flex;
  justify-content: space-between; /* To evenly distribute buttons horizontally */
}

.center-buttons {
  display: flex;
  position: relative;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 20%; /* Optional: Add margin for spacing */
}

/* In your App.css file */
.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 86vh; /* Make the container fill the full viewport height */
  flex-grow: 1; /* Grow to fill remaining vertical space */
  overflow-y: auto; /* Enable vertical scrolling when content overflows */
  margin-top: 0px; /* Adjust the margin-top value as needed */
  padding-top: 90px; /* Add padding to the top */
  padding-bottom: 60px;
}

/* Default styles for small screens (mobile) */
.container {
  min-height: 100vh; /* Minimum viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 60px; /* Adjust as needed for your bottom navbar's height */
}

/* Styles for screens larger than 768px (tablets and desktops) */
@media (min-width: 768px) {
  .container {
    padding: 20px;
  }
}

/* Default font size for small screens */
p {
  font-size: 28px;
}

/* Larger font size for screens larger than 768px */
@media (min-width: 768px) {
  p {
    font-size: 30px;
  }
}

.card {
  width: 100%;
  max-width: 300px;
  margin: 10px;
}

/* Adjust the bottom navbar height */
.navbar.fixed-bottom {
  padding: 10px 0; /* Increase top and bottom padding */
  min-height: 100px; /* Increase the minimum height */
}

/* Custom card styles */
.custom-card {
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.navbar-dark {
  background-color: #101010;
  /* Add other styles as needed */
}

.fixed-title {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  color: white;
  text-align: center;
  padding: 12px;
  z-index: 1;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  font-weight: normal;
  font-size: 2rem; /* Adjust the font size as needed */
}

.app-container {
  margin-top: 10px; /* Adjust the margin to make content below the fixed title visible */
}

/* Add this CSS to your App.css */
.popup {
  background-color: #212121;
  border-radius: 26px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px; /* Set the desired width */
  height: 340px; /* Set the desired height */
  color: #ffffff; /* Set the desired text color */
  font-size: 10px; /* Set the desired font size */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.popup h2 {
  /* Styles for heading elements */
  color: #ffffff; /* Set the desired heading text color */
  font-size: 16px; /* Set the desired heading font size */
  margin-bottom: 30px;
}

.popup input {
  /* Styles for input elements */
  font-size: 20px; /* Set the desired input font size */
  border-radius: 30px;
  text-align: center;
  width: 200px;
  height: 36px;
  border: none;
  background-color: #404040;


}

.button-container {
  display: flex;
  justify-content: center;
}

/* Style for the buttons */
.popup .submit-button, .popup .cancel-button {
  background-color: #66BB6A;
  color: white;
  font-size: 13px;
  padding: 8px 16px;
  margin: 0 8px;
  border-radius: 30px;
  border: none;
  width: 90px;
  height: 34px;
  text-align: center;
  margin-top: 26px;
}

/* Style for the submit button */
.popup .submit-button {
  background-color: #42A5F5;
}

/* Style for the cancel button */
.popup .cancel-button {
  background-color: #EF5350;
}

.custom-input {
  color: #ffffff; /* Change this to the desired color */
  font-weight: bold;
}
