@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Extra+Condensed&display=swap');


.background-light {
  background-color: #ffffff;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 180px;
}

/* Default styles for small screens (mobile) */
.container {
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 80px;
}

/* Styles for screens larger than 768px (tablets and desktops) */
@media (min-width: 768px) {
  .container {
    padding: 20px;
  }
}

/* Default font size for small screens */
p {
  font-size: 28px;
}

/* Larger font size for screens larger than 768px */
@media (min-width: 768px) {
  p {
    font-size: 30px;
  }
}

.card {
  width: 100%;
  max-width: 300px;
  margin: 20px;
}

/* Adjust the bottom navbar height */
.navbar.fixed-bottom {
  padding: 10px 0;
  min-height: 100px;
}

.custom-card {
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.navbar-light {
  background-color: #F5F5F5;
}

.top-bar-light {
  position: fixed;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;    
  background-color: #ffffff;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
}

.app-title-light {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: black;
  text-align: center;
  padding: 12px;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  font-weight: normal;
  font-size: 2rem;
}

.mode-toggle-button-container-light {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 10px 10px;
  border-radius: 100px;
  cursor: pointer;
  font-size: 16px;
}

.mode-toggle-button-light {
  z-index: 1;
  padding: 6px;
  border-radius: 100px;
  border: none;
  font-size: 22px;
  background-color: #ffffff;
  color: #000000;
  width: 40px;
  height: 40px;
}

.popup-light {
  background-color: #F5F5F5;
  border-radius: 30px;
  border: 2px solid #BDBDBD;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: 260px;
  color: black;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Styles for heading elements */
.popup-light h2 {
  color: black;
  font-size: 18px;
  margin-bottom: 30px;
}

.popup-light input {
  font-size: 20px;
  border-radius: 30px;
  text-align: center;
  width: 200px;
  height: 36px;
  border: none;
  background-color: #e6e6e6;
}

.button-container {
  display: flex;
  justify-content: center;
}

/* Style for the buttons */
.popup-light .submit-button, .popup-light .cancel-button {
  background-color: #66BB6A;
  color: white;
  font-size: 13px;
  padding: 8px 16px;
  margin: 0 8px;
  border-radius: 30px;
  border: none;
  width: 90px;
  height: 34px;
  text-align: center;
  margin-top: 26px;
}

/* Style for the submit button */
.popup-light .submit-button {
  background-color: #42A5F5;
}

/* Style for the cancel button */
.popup-light .cancel-button {
  background-color: #EF5350;
}

.custom-input-light {
  color: black; /* Change this to the desired color */
  font-weight: bold;
}

.daily-expenses-card {
  border: none;
  border-radius: 40px;
  color: #FF7043;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  margin-top: 10px;
}

.chart-container {
  width: 300px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
